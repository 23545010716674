import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Myanmar",
  "subtitle": "February 2016",
  "category": "Asia"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`Local SIM - I picked one up with `}<a parentName="li" {...{
          "href": "https://mpt.com.mm/en/"
        }}>{`MTP`}</a></li>
      <li parentName="ul">{`Bring Mosquito repellent!`}</li>
      <li parentName="ul">{`Haggle with the taxis! It's very obvious who's a tourist`}</li>
      <li parentName="ul">{`Overnight buses cost about 1/5 of a plane ticket`}</li>
    </ul>
    <h3 {...{
      "id": "temples"
    }}>{`Temples`}</h3>
    <ul>
      <li parentName="ul">{`They don't like exposed skin`}</li>
      <li parentName="ul">{`You'll have to take your shoes off`}</li>
      <li parentName="ul">{`There's a custom to pour water in the "birth day" area. Wednesday has a morning and night so do'nt pour it in to the wrong one!`}</li>
    </ul>
    <h3 {...{
      "id": "souvenirs"
    }}>{`Souvenirs`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Longyi"
        }}>{`Longyi`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Most temples don't like exposed skin`}</li>
          <li parentName="ul">{`Outskirt tours in Mandalay will take you to weaving shops`}</li>
          <li parentName="ul">{`I was told, the locals usually buy fabric to bring to a tailor and the pre-made ones have a tie which are mainly for tourists`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Lotus Silk Scarves`}</li>
      <li parentName="ul">{`Sand Paintings - Plenty in Bagan`}</li>
      <li parentName="ul">{`Sun paintings - Plenty in Bagan`}</li>
      <li parentName="ul">{`Tapestries - Won't fit in your carry on though`}</li>
    </ul>
    <h3 {...{
      "id": "scams"
    }}>{`Scams`}</h3>
    <ul>
      <li parentName="ul">{`One can easily be swindled in to a tour or a horoscope reading not knowing that they will "request donations" at the end`}</li>
      <li parentName="ul">{`Kids will offer plastic bags to "hold your shoes" but won't return them to you unless you "give them money"; they won't take change and they're quite aggressive`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Burmese_milk_tea"
        }}>{`Burmese milk tea`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Youtiao"
        }}>{`E kya kway`}</a>{` - Same as Chinese fried dough (油條) and often eaten with Mohinga`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Lahpet"
        }}>{`Laphet`}</a>{` - Known as fermented or pickled tea and is not only drank but eaten`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Mohinga"
        }}>{`Mohinga`}</a>{` - Slightly differs by region`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Naan"
        }}>{`Nan bya`}</a>{` - Naan with pè byouk (spouted yellow peas)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Ohn_no_khao_sw%C3%A8"
        }}>{`Ohn no khao swè`}</a>{` - Curry chicken with noodles`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Paratha"
        }}>{`Palata`}</a>{` - Paratha with egg or mutton`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Samosa"
        }}>{`Samuza`}</a>{` - Basically smaller samosas`}</li>
      <li parentName="ul">{`Shan khao swé - Also known as Shan noodles. It’s rice noodles with chicken or pork and garnishes. Eat it in Mandalay!`}</li>
    </ul>
    <h2 {...{
      "id": "yangon"
    }}>{`Yangon`}</h2>
    <ul>
      <li parentName="ul">{`There's a ban on motorbikes and scooters so catch a taxi. Don't forget to bargin because they charge by distance. Also, so much traffic!`}</li>
    </ul>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nr1EHRt7RJcbpYTk9"
        }}>{`999 shan noodle`}</a>{` - Sticky shan noodles with pork was very good and inexpensive! 10 min walk from Bogyoke Aung San Market`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/iogceYvQGXuubohGA"
        }}>{`Rangoon tea house`}</a>{` - I loved this tea house; everything was delicious!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/odPUjTsiXMFeu8wX6"
        }}>{`Nagasaki tei`}</a>{` - They only spoke Burmese and Japanese when I was there; Had a private room to myself.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zXmhXEFousszdLfL9"
        }}>{`Happy cafe & noodles`}</a>{` - Had an english menu`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/ZXwXdRphTjRFXhQH7"
          }}>{`Circle train`}</a>{` (`}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/Yangon_Circular_Railway"
          }}>{`wiki`}</a>{`)`}</p>
        <ul parentName="li">
          <li parentName="ul">{`It takes around 3 hours but not all trains do the full "tourist" loop. The train operator tried to explain it but it was a bit lost in translation. If you get on the wrong train, you can just wait for the right one.`}</li>
          <li parentName="ul">{`It's a very old train so don't expect it to be comfy. There were also vendors walking through each car selling goods.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "http://www.shwedagonpagoda.com.mm/"
          }}>{`Shwedagon Pagoda`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`It's a really nice temple and a great place for sunrise and sunset. Foreigners pay extra for entrance. Bring a plastic bag for your shoes so kids don't try to scam you.`}</li>
          <li parentName="ul">{`East entrance has lots of vendors`}
            <ul parentName="li">
              <li parentName="ul">{`Exit here for `}<a parentName="li" {...{
                  "href": "https://maps.app.goo.gl/gmyh7jwkiuXisAdM6"
                }}>{`Kandawgyi lake`}</a>{` and `}<a parentName="li" {...{
                  "href": "https://maps.app.goo.gl/YyAF6n89bn2tW9cM6"
                }}>{`Karaweik palace`}</a>{`; 15 minute walk`}</li>
            </ul>
          </li>
          <li parentName="ul">{`West entrance has an escalator`}
            <ul parentName="li">
              <li parentName="ul">{`Exit here for `}<a parentName="li" {...{
                  "href": "https://maps.app.goo.gl/KgJwcRFAnjJjiXir6"
                }}>{`People's Park`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/M6wvWqTWERXp7CKMA"
          }}>{`Chaukhtatgyi Buddha Temple`}</a>{` - Impressive reclining Buddha but not the biggest`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/uM16NsYJiVK4k1Gs6"
            }}>{`Maha Satkya Atulamanaung Ngarhtatgyi`}</a>{` is across the street`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://goo.gl/maps/wdoEguPGwSXFrmfb9"
          }}>{`Botahtaung Pagoda`}</a>{` - Don't get roped in to a horoscope reading unless you want one`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://goo.gl/maps/gn5z8uGapJ81DxrM9"
          }}>{`Bogoyoke Aung San Market`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "bagan"
    }}>{`Bagan`}</h2>
    <ul>
      <li parentName="ul">{`Most hostels were in New Bagan while the resorts were in Old Bagan`}</li>
      <li parentName="ul">{`I believe there's actually an entrance fee to Bagan? However the driver I flagged down told me he didn't support the government and took me on a detour. I was never asked to show any ticket anywhere.`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1RCD47TEY95tReS48"
        }}>{`Weatherspoons`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/DnvrVgp3e8ca3bNx5"
        }}>{`7 sisters`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/WPK6AQnA5Ek77B7V9"
        }}>{`Kyaw Kitchen`}</a>{` - Bring mosquito repellant!`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wpj4DpsWhFVv3FTw8"
        }}>{`Post office`}</a>{` - Finally a post office! (New Bagan)`}</li>
    </ul>
    <h3 {...{
      "id": "temple-hopping"
    }}>{`Temple hopping`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Highly recommend renting an electric scooter for temple hopping. Horse cart and taxi hires were more expensive and are unable to go to the smaller temples.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The scooter renter assured me it was easy to learn. I started with the smaller bike and upgraded to the bigger one on the 2nd day.`}</li>
          <li parentName="ul">{`It wasn't expensive and there's a big shop in front of `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/ZkuRh41X92ce4oZY6"
            }}>{`Ostello Bello hostel`}</a></li>
          <li parentName="ul">{`There's a single main road with few forks so it's relatively hard to get lost`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/132336xRTATQ2hHU8"
          }}>{`Ananda Temple`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/Nx6MBgqV4jM57NLY7"
          }}>{`Bupaya`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/WvC777me8qjMzBS8A"
          }}>{`Dhammayangyi Temple`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/s9b4gK2oZmGR8mAh6"
          }}>{`Dhammayazaka Pagoda`}</a>{` - For sunrise`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://goo.gl/maps/2kTEqnXuugsKFP6bA"
          }}>{`Htilominlo Pahto`}</a>{` - Had a great view of the big temple`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/8okrfkyRvonVHVTj6"
          }}>{`Lawkananda Pagoda`}</a>{` - By the river`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://goo.gl/maps/x65arJx1bHs8b1Em9"
          }}>{`Manuha Temple`}</a>{` - Houses a reclining Buddha that barely fits in the room!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/YmemWD5vvJeN4TE7A"
          }}>{`Naga Yon Hpaya`}</a>{` - I saw the sunrise here which was nice but the hot air balloons were really far away in my photos.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/7ZFskpWCsWAs7BG4A"
          }}>{`Pyathetgyi Pagoda`}</a>{` - Panoramic view was great for sunset. The road leading here was sandy so careful on the scooter.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/hQQqfvESyfHKiEUQ6"
          }}>{`Saydanagyi`}</a>{` - Lined with elephants. There's a "window entrance" in front of a seller. I believe it's a small bat cave but I could have very well been lost in translation...`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/M7bU1qtfC8pnuQ269"
          }}>{`Shwesandaw Pagoda`}</a>{` - Crowded since it's famous and popular for sunrise`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://goo.gl/maps/NqGrzCbPFvgywLXL8"
          }}>{`Sulamani Temple`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/5Y3PGrhWg8WDaKBc9"
          }}>{`Thitsarwadi`}</a>{` - The only 3 story pagoda. If it's still possible, go to the 3rd story and squeeze through a gap to sit on the ledge. Space is limited so get here early to watch the sunset.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/ZuojsEaydf7PCJJc7"
          }}>{`Yatana kan kyaung`}</a>{` - Not the most interesting but was the highlight along a boring stretch of road`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "mount-popa"
    }}>{`Mount Popa`}</h2>
    <ul>
      <li parentName="ul">{`A half day is sufficient and it's about an hour by car from Bagan. The driver might take you to some food stands in the middle of no where for a break to "shop" for ~10 minutes.`}</li>
      <li parentName="ul">{`Carry nothing that dangles with you or leave it in the car! The monkeys will attempt to take it!! They are very “friendly” monkeys but will not return your stuff!`}</li>
      <li parentName="ul">{`Bring wet wipes because there’s a lot of monkey poop`}</li>
    </ul>
    <h2 {...{
      "id": "monywa"
    }}>{`Monywa`}</h2>
    <ul>
      <li parentName="ul">{`I got here from Bagan which I read had a direct bus. However I wasn't able to find it and ended up taking a bus first to Pakokku. When I arrived in Pakokku, I had to hire a motorbike to the transfer station. Not a single person spoke English so I just repeated "Monywa" to everyone and they understood. I even had to draw a photo of a toilet to find one.`}</li>
      <li parentName="ul">{`This town had very few tourist and I suggest hiring a private motorbike to tour around. They will wait for you and take you anywhere you want to go for a flat fee.`}</li>
    </ul>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MBjKnmdzgbCuPDEWA"
        }}>{`Eureka Cafe`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/DV69vFe1QxLVc8wS9"
        }}>{`Thanboddhay Pagoda`}</a>{` - I thought I was sick of seeing temples after Bagan, but this was so impressive!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3UVXUm2S5pfXWd1w5"
        }}>{`Maha Bodhi Ta Htaung`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Second largest giant standing buddha statue in the world. It’s hollow inside so you can walk up.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/WRPaXGNcJh2bp4CMA"
            }}>{`Reclining Buddha`}</a>{` in the same area was also impressive`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/UyUZ5Rk3eLSJeZBa6"
            }}>{`Thousand Buddhas`}</a>{` - Honestly, everything in this area was wow!`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/5AmHdV9R5N8UBGKk8"
        }}>{`Shwezigon Paya`}</a>{` - It was near the hotel so I went to look.`}</li>
      <li parentName="ul">{`Chindwin River is nice for sunset.`}</li>
    </ul>
    <h2 {...{
      "id": "mandalay"
    }}>{`Mandalay`}</h2>
    <ul>
      <li parentName="ul">{`I'm not a fan of big cities but this is a huge hub and most transfers run through here`}</li>
    </ul>
    <h3 {...{
      "id": "food-4"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/GRBCWVnj77mnyAU27"
        }}>{`MinGaLaBar restaurant`}</a>{` - Food was good and at a great price`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/aRvoqxoy9TFs79AM8"
        }}>{`Cafe city`}</a>{` - Across from Mandalay Palace serving western food`}</li>
      <li parentName="ul">{`Ah ka min teahouse - On 64th and 26th. It was almost entirely men watching sports TV at night aside from me. Some people gave me looks, but the food and tea were both good. My taxi driver recommended it to me. I just pointed at the things I wanted cause no one spoke any English.`}</li>
      <li parentName="ul">{`Shwe Son Min teahouse - I just love their tea houses! A different driver recommended this one.`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-3"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MZc3f64YJHTkEYNTA"
        }}>{`Shweinbin Monastery`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Shweinbin_Monastery"
        }}>{`wiki`}</a>{`) - Built in traditional Burmese teakwood`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/GoVu355xuxcB9uBk8"
        }}>{`Skinny Buddha`}</a>{` - It was unique!`}</li>
    </ul>
    <h4 {...{
      "id": "foreigner-archaeological-combo-ticket"
    }}>{`Foreigner Archaeological Combo Ticket`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/g1gRH4ejSrj2oGba9"
        }}>{`Atumashi Monastery`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Atumashi_Monastery"
        }}>{`wiki`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/f1bZpp7vvnWxFe7d8"
        }}>{`Kuthodaw Pagoda`}</a>{`  (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Kuthodaw_Pagoda"
        }}>{`wiki`}</a>{`) - Known as the world’s largest book as there are 729 marble slabs inscribed with Buddhist teachings. Each white shrine represents a page.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Gy4Tmm8LK6Jspu4R6"
        }}>{`Mandalay hills`}</a>{` - Was aiming for the sunset but missed it`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Pdz9RVie4KXgCNEL7"
        }}>{`Mandalay Palace`}</a>{` -  Climb the watchtower for a better view`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/w7q3hhdd7TtrMXXYA"
        }}>{`Shwenandaw Monastery`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Shwenandaw_Monastery"
        }}>{`wiki`}</a>{`) - The monastery was the same carved teak wooden that used to be part of the Royal Palace`}</li>
    </ul>
    <h4 {...{
      "id": "markets"
    }}>{`Markets`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Kvke5WgogZzqCzMV7"
        }}>{`Jade and Gemstones Market`}</a>{` - There’s a sign that says K1000 foreign fee but I don't believe I had to pay...`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/LvapSG95rNauewpZ8"
        }}>{`Zay Cho`}</a>{` - Very similar to Yangon’s market`}</li>
      <li parentName="ul">{`Morning Flower Market - The google link I had prior no longer works; Ask the locals! It's a famous market.`}</li>
    </ul>
    <h3 {...{
      "id": "mandalay-outskirts-excursions"
    }}>{`Mandalay outskirts (excursions)`}</h3>
    <ul>
      <li parentName="ul">{`I think all the tours take the same route... There was a lot of "shopping" involved even though I hired a private taxi (with AC). He only spoke tourist English. My day started at 8:30am and ending after sunset.`}</li>
    </ul>
    <h4 {...{
      "id": "south-of-the-city"
    }}>{`South of the city`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wSJoK8EATctDCRrN8"
        }}>{`Mahamuni Pagoda`}</a>{` - Males are allowed to add gold leaf to the Buddha`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/uy1cyctaoiLdvemW9"
        }}>{`Aung Nan`}</a>{` - Learn how artisans craft wood`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/LkFSHQxHWM41dFgS7"
        }}>{`King Galon Gold Leaf Workshop`}</a>{` - Learn how artisans craft gold leaf`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/52sTqZoCU797BVZL8"
        }}>{`Mahagandhayon Monastery`}</a>{` - Was a popular tourist attraction to see monks eat lunch but I felt kind of bad that they were on display. Cool cultural sighting though...!`}</li>
    </ul>
    <h4 {...{
      "id": "inwa"
    }}>{`Inwa`}</h4>
    <ul>
      <li parentName="ul">{`Most people came for the horse cart tour. I didn't like my horse cart guy so I had a subpar experience.`}</li>
    </ul>
    <h4 {...{
      "id": "sagaing"
    }}>{`Sagaing`}</h4>
    <ul>
      <li parentName="ul">{`Hilltop walk was alright.`}</li>
    </ul>
    <h4 {...{
      "id": "amarapura"
    }}>{`Amarapura`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/oyt1Ka8BHRA3NrTY9"
        }}>{`U-Bein bridge`}</a></li>
      <li parentName="ul">{`I was taken to a few weaving shops for demonstration and shopping`}</li>
    </ul>
    <h2 {...{
      "id": "mawlamyine"
    }}>{`Mawlamyine`}</h2>
    <ul>
      <li parentName="ul">{`I was headed to Hpa-an from Mandalay via bus so I had to stop here first`}</li>
    </ul>
    <h3 {...{
      "id": "food-5"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul">{`Southern style Mohinga served at many shops along Strand Road`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AU7Hx4ZeJ1o8rXk39"
        }}>{`Shwe Nandaw`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-4"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lonelyplanet.com/myanmar-burma/mawlamyine/attractions/nwa-la-bo-pagoda/a/poi-sig/1368246/1002365"
        }}>{`Nwa Le Bo Pagoda`}</a>
        <ul parentName="li">
          <li parentName="ul">{`I was told it’s also known as the Three Stones Pagoda and that it's only reachable some parts of the year?`}</li>
          <li parentName="ul">{`I hired a scooter taxi take me to Kyonka village first. Then I hopped on a pickup truck (hold on tight, it's life-threatening I swear) who didn't collect money until midway up the mountain for unknown reasons. The truck doesn't leave until it's mostly full so there's a bit of a wait.`}</li>
          <li parentName="ul">{`The sun is blazing at the top so go earlier in the day`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/H8xAXKALC6Ryycxr7"
        }}>{`Kyaik Tha Lan Pagoda`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Kyaikthanlan_Pagoda"
        }}>{`wiki`}</a>{`) - Very much like Shwedagon (in Yangon) but on top of a hill. Relaxing place to people watch and for sunset.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/fkJ34kF3XenTNF8x8"
        }}>{`Taung Pauk Monastery`}</a>{` - Bamboo thread buddha inside`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NjcDBGdCGZSPXsK17"
        }}>{`Kyaiktiyo Pagoda`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Kyaiktiyo_Pagoda"
        }}>{`wiki`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "hpa-an"
    }}>{`Hpa-an`}</h2>
    <ul>
      <li parentName="ul">{`My favorite little town!`}</li>
    </ul>
    <h3 {...{
      "id": "food-6"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/BPrVHHdL9SbkRZxm8"
        }}>{`San Ma Tau`}</a>{` - Traditional Burmese food; lots of small side dishes`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/TBE2KBvFLUPLbhae6"
        }}>{`Khit thit`}</a>{` - Chinese Burmese food`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-5"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/LhCpQSQFiwSG6GuWA"
        }}>{`Pha Bhu Taung Pagoda / Mount Hpan Pu`}</a>{` - Take a boat behind Shwe Yin Hmyaw Pagoda. I was told they stop the boats after something like 6 or 7, so definitely ask since it’s across the Thanlyin river.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/s7vnW3zvb3fJiVqV8"
        }}>{`Shwe Yin Hmyaw Pagoda`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Shwe_Yin_Myaw_Pagoda"
        }}>{`wiki`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "caves-tour"
    }}>{`Caves tour`}</h3>
    <ul>
      <li parentName="ul">{`I did a shared tuk-tuk ride and it was so worth it!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/5QuA1Z4jukthuVpr9"
        }}>{`Kaw Ka Thaung Cave`}</a>{` - Lots of monk statues around this cave`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gLwGbfCqtarn5GmP9"
        }}>{`Mahar Sadan Cave`}</a>{` - Walk through a cave full of bat poo before taking a wooden ferry back through the fields`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/xpcU6vbJqbFXgsi26"
        }}>{`Yae Ta Khon`}</a>{` - This area was very popular for lunch and we saw lots of locals dip in the waters`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/73gYeR4zg9opdwbv8"
        }}>{`Mount Zwegabin`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Mount_Zwegabin"
        }}>{`wiki`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/SnjbQDeDeHpCgtDp9"
        }}>{`Lumbini Buddha Garden`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/fpMw9JnNnobZoMsR7"
        }}>{`Kyaut Ka Latt Pagoda`}</a>{` - Monastery built in the middle of an artificial lake`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Cgh5F4rxpFxEaD2d7"
        }}>{`Kaw Gon Cave`}</a>{` - Pay entrance fee or walk up the stairs behind for free. The ancient carvings and inscriptions are a neat sight!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/5HmosfiiDF7HoV2EA"
        }}>{`Yathae Pyan Cave Viewpoint`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/oZZHsVoK1WwakCV76"
        }}>{`Bat Cave`}</a>{` - By far the coolest ending. It smelled like poo but it was a sight to see so many bats fly out during sunset. Stand by the stairs behind the house for a great view!`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      